/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types, max-len, jsx-a11y/label-has-for */
import React from 'react';
import Textarea from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';
import { FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import ProjectDropdown from '../ProjectDropdown';
import formMessages from './messages';
import Spinner from '../Spinner';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import IssueDropDown from '../IssueDropDown';
import ColorPicker from '../ColorPicker';
import { createHackySelectFieldHandlers } from './hackyMcHackHacks';

// workaround for https://www.chromestatus.com/features/5093566007214080
const onTouchStart = event => {
  event.target.focus();
};

const renderInput = ({
  input,
  label,
  type,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  disabled,
  autoFocus,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && <label htmlFor={id}>{label}</label>}
    <input
      {...input}
      id={id}
      type={type}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);
const selectStyles = {
  menu: styles => ({ ...styles, zIndex: 999 }),
};
const renderSelectProject = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
  options,
  customError,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <Select
      {...input}
      options={options}
      onBlur={event => event.preventDefault()} // fix for mobile selection not working https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
      isSearchable
      placeholder={placeholder}
      styles={selectStyles}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (customError && <p className="bg-danger">{customError}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderMultiSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
  options,
  disabled,
  customError,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <Select
      {...input}
      options={options}
      onBlur={event => event.preventDefault()} // fix for mobile selection not working https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
      isMulti
      isSearchable
      isDisabled={disabled}
      placeholder={placeholder}
      styles={selectStyles}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (customError && <p className="bg-danger">{customError}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);
const renderFileInput = ({
  input,
  label,
  meta: { asyncValidating, touched, error, warning },
  id,
  onFileChange,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && (
      <label
        htmlFor={id}
        className="btn btn-default btn-block btn-file btn-file"
      >
        {label}
      </label>
    )}
    <input
      id={id}
      type="file"
      className="form-control"
      style={label ? { display: 'none' } : {}}
      onTouchStart={onTouchStart}
      onChange={onFileChange}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderCheckbox = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  style,
  disabled,
}) => (
  <div className="checkbox" style={style}>
    <label htmlFor={id}>
      <input
        {...input}
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={input.value}
      />
      {label}
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderRadio = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
}) => (
  <div className="radio">
    <label htmlFor={id}>
      <input {...input} id={id} type={type} />
      {label}
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  children,
  disabled,
  noDefaultLabel,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <select {...input} id={id} className="form-control" disabled={disabled}>
      {noDefaultLabel && (
        <option hidden disabled value="">
          {noDefaultLabel}
        </option>
      )}
      {children}
    </select>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelectAlt = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  options,
  customError,
  disabled,
  selectRef,
  withColor,
}) => {
  // Work Category feature START
  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: input.value.color || color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: '8px',
      height: 12,
      width: 12,
    },
  });
  const stylesWithColor = () => ({
    ...selectStyles,
    input: styles => ({ ...styles, ...dot() }),
    singleValue: styles => ({ ...styles, marginLeft: '20px' }),
  });
  // Work Category feature END

  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        {...input}
        options={options}
        onBlur={event => event.preventDefault()}
        isDisabled={
          disabled // fix for mobile selection not working https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
        }
        {...(withColor && { styles: { ...stylesWithColor() } }) || {
          styles: selectStyles,
        }}
        {...createHackySelectFieldHandlers(selectRef, options)}
      />
      {touched &&
        ((error && <p className="bg-danger">{error}</p>) ||
          (customError && <p className="bg-danger">{customError}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

const renderProjectSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  projects,
  customError,
  disabled,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <ProjectDropdown
      projects={projects}
      input={input}
      onChange={value => {
        input.onChange(value);
      }}
      onBlur={event => event.preventDefault()} // fix for mobile selection not working https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
      value={input.value}
      disabled={disabled}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (customError && <p className="bg-danger">{customError}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderIssueSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  issues,
  customError,
  disabled,
  onInputChange,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <IssueDropDown
      issues={issues}
      input={input}
      onChange={value => input.onChange(value)}
      onInputChange={value => onInputChange(value)}
      onBlur={event => event.preventDefault()} // fix for mobile selection not working https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
      // onBlur={() => input.onBlur(input.value)}
      value={input.value}
      disabled={disabled}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (customError && <p className="bg-danger">{customError}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderTextarea = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
  disabled,
  fixWidth,
}) => (
  <div className={`form-group ${fixWidth ? 'fixedFormWidth' : ''}`}>
    {label && <label htmlFor={id}>{label}</label>}
    <Textarea
      {...input}
      id={id}
      className="form-control"
      placeholder={placeholder}
      disabled={disabled}
    >
      {input.value}
    </Textarea>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderNumber = ({
  input,
  label,
  pattern,
  step,
  min,
  max,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  disabled,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && <label htmlFor={id}>{label}</label>}
    <input
      {...input}
      id={id}
      type="number"
      pattern={pattern}
      step={step}
      min={min}
      max={max}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // ref={el => autoFocus && el && el.focus()}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderCurrency = ({
  input,
  label,
  pattern,
  step,
  min,
  max,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  currency,
  disabled,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && (
      <label htmlFor={id}>
        {label} {currency}
      </label>
    )}
    <input
      {...input}
      id={id}
      type="number"
      pattern={pattern}
      step={step}
      min={min}
      max={max}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // ref={el => autoFocus && el && el.focus()}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

// eslint-disable-next-line react/prop-types, max-len, prettier/prettier
const renderTime = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  handleUpdate,
  manualUpdate,
  initialValue,
  disabled,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div className="input-group">
      <TimePicker
        meta={{
          touched,
          error,
          warning,
        }}
        manualUpdate={manualUpdate}
        input={input}
        handleUpdate={handleUpdate}
        disabled={disabled}
        initialValue={initialValue}
      />
    </div>
  </div>
);

// eslint-disable-next-line react/prop-types, max-len, prettier/prettier
const renderDate = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
  disabled,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div className="input-group">
      <DatePicker
        input={input}
        meta={{
          touched,
          error,
          warning,
        }}
        className="form-control"
        placeholder={placeholder}
        disabledKeyboardNavigation
        disabled={disabled}
      />
    </div>
  </div>
);

/* eslint-disable react/no-array-index-key */
const renderGenericFieldArray = ({
  fields,
  meta: { error },
  name,
  renderField,
  withAdd = true,
  withRemove = true,
  pushValue,
  singleRows,
}) => {
  const classNames = ['field-array'];
  const removeButtonClassNames = ['btn', 'btn-red'];

  if (singleRows) {
    classNames.push('field-array--single-rows');
    removeButtonClassNames.push('btn--no-border');
    removeButtonClassNames.push('btn--no-hover');
    removeButtonClassNames.push('remove-button');
  }

  return (
    <ul className={classNames.join(' ')}>
      {fields.map((field, index) => (
        <li key={`${name}__${index}`} className="clearfix">
          {renderField(field, index, fields)}
          {withRemove && (
            <button
              type="button"
              className={removeButtonClassNames.join(' ')}
              onClick={() => fields.remove(index)}
            >
              <FaTrash />
              {!singleRows && <FormattedMessage {...formMessages.remove} />}
            </button>
          )}
        </li>
      ))}
      {error && <li className="error">{error}</li>}
      {withAdd && (
        <li>
          <hr />
          <button
            type="button"
            className="btn btn-green"
            onClick={() => fields.push(pushValue)}
          >
            <i className="fa fa-plus fa--prepended" />
            <FormattedMessage {...formMessages.add} />
          </button>
        </li>
      )}
    </ul>
  );
};
/* eslint-enable react/no-array-index-key */

const renderColoPicker = props => <ColorPicker {...props} />;

export default {
  renderColoPicker,
  renderInput,
  renderNumber,
  renderFileInput,
  renderCheckbox,
  renderRadio,
  renderSelect,
  renderSelectAlt,
  renderTextarea,
  renderTime,
  renderDate,
  renderGenericFieldArray,
  renderProjectSelect,
  renderIssueSelect,
  renderMultiSelect,
  renderSelectProject,
  renderCurrency,
};
