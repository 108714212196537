import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { createHackySelectFieldHandlers } from '../ReduxForm/hackyMcHackHacks';

const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  menu: styles => ({
    ...styles,
    zIndex: 10000,
  }),
  option: (styles, { data }) => ({
    ...styles,
    paddingLeft: data.isCategory ? '25px' : null,
    fontWeight: data.isCategory ? 'normal' : 'bold',
  }),
};

class IssueDropDown extends React.Component {
  static propTypes = {
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        iid: PropTypes.number.isRequired,
        project_id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onBlur: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    onInputChange: () => {},
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  render() {
    const { input, onInputChange, onBlur, disabled, issues } = this.props;
    const options = issues.reduce((all, issue) => {
      all.push({
        value: issue.iid,
        label: `#${issue.iid} ${issue.title}`,
      });
      return all;
    }, []);
    options.unshift({
      value: null,
      label: 'No Issue',
    });
    return (
      <Select
        {...input}
        options={options}
        styles={selectStyles}
        onBlur={onBlur}
        isDisabled={disabled}
        {...createHackySelectFieldHandlers(this.selectRef, options)}
        onInputChange={onInputChange}
      />
    );
  }
}

export default IssueDropDown;
